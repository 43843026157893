import Header from './Header';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import ChatWidget from './chat-widget';

const Layout = ({ changeColorWhileScroll, children }) => {
  return (
    <>
      <ScrollToTop />
      <Header changeColorWhileScroll={changeColorWhileScroll} />
      {children}
      {process.env.REACT_APP_ISENABLED && (<ChatWidget />)}
      <Footer />
    </>
  );
};

export default Layout;
