import { useState, useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import TradingViewWidget from "../../pages/Dashboard/Coin/TradingView";
import useAuth from "../../hooks/zustand/useAuth";
import useEmblaCarousel from "embla-carousel-react";
// components
import SectionTitle from "../SectionTitle";
import { useTranslation } from "react-i18next";
import TabNavigationDashboard from "../dashboard/tabs/Tabs-navigation-dashboard";
import i18next from "i18next";
// import ButtonLp from '../ButtonLp';
// mock data
// import { mockInvest } from '../../mock/mockData';

const HomeInvest = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "center",
    containScroll: "trimSnaps",
    loop: false,
  });

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  const productTabList = ['Crypto', 'Share Index'];

  const [tabSelected, setTabSelected] = useState({
    currentTab: 0,
    noTab: productTabList.length
  });

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  const isLogin = useAuth();

  const BTCReff = useRef(null);
  const ETHReff = useRef(null);
  const DOTReff = useRef(null);
  const HTReff = useRef(null);
  const XRPReff = useRef(null);
  const DOGEReff = useRef(null);
  const BNBReff = useRef(null);
  const TRXReff = useRef(null);
  const SOLReff = useRef(null);
  const USDTReff = useRef(null);

  const SPXReff = useRef(null);
  const VIXReff = useRef(null);
  const DXYReff = useRef(null);
  const DAXReff = useRef(null);
  const HSIReff = useRef(null);
  const CAC40Reff = useRef(null);
  const NASDAQReff = useRef(null);
  const NIKKEIReff = useRef(null);

  const CLReff = useRef(null);
  const NGReff = useRef(null);
  const GCReff = useRef(null);
  const PLReff = useRef(null);
  const LTHReff = useRef(null);
  const NLReff = useRef(null);
  const COHReff = useRef(null);
  const COPPERReff = useRef(null);
  const { t } = useTranslation();

  

  return (
    <div className="container mx-auto max-w-screen-xl px-5 mt-20">
      <SectionTitle>{t("home.section2.title")}</SectionTitle>
      <div className="pt-10">
        <section className="max-w-full" aria-multiselectable={false} >
          <div>
            <ul className="flex items-center justify-center w-full" role="tablist">
              { 
                productTabList.map((value, index) => {
                  return (
                    <TabNavigationDashboard 
                      key={index}
                      tabSelected={tabSelected}
                      setTabSelected={setTabSelected}
                      tabNum={index}
                      label={value}
                      className="text-black"
                    />
                  );
                })
              }
            </ul>
          </div>
          <div>
            {tabSelected.currentTab === 0 && (
              <div ref={emblaRef} className="overflow-hidden">
                <div className="flex py-10 gap-4 justify-center ">
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/bitcoin"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="COINBASE:BTCUSD"
                        containerRef={BTCReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/ethereum"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="COINBASE:ETHUSD"
                        containerRef={ETHReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/polkadot"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="COINBASE:DOTUSD"
                        containerRef={DOTReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/houbitoken"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="CRYPTO:HTUSD"
                        containerRef={HTReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56  h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/ripple"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="COINBASE:XRPUSD"
                        containerRef={XRPReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/dogecoin"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="BINANCE:DOGEUSD"
                        containerRef={DOGEReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/binance"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="BINANCE:BNBUSD"
                        containerRef={BNBReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/tron"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="BINANCE:TRXUSD"
                        containerRef={TRXReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/solana"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="BINANCE:SOLUSD"
                        containerRef={SOLReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {tabSelected.currentTab === 1 && (
              <div ref={emblaRef} className="overflow-hidden">
                <div className="flex py-10 gap-4 justify-center ">
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/sp500"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="FRED:SP500"
                        containerRef={SPXReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  {/* <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/us100"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="TVC:NDQ"
                        containerRef={NDQReff}
                        type="mini-chart"
                      />
                    </div>
                  </div> */}
                  {/* <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/dowjones"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="DJ:DJI"
                        containerRef={DJIReff}
                        type="mini-chart"
                      />
                    </div>
                  </div> */}
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/usdollarindex"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="CAPITALCOM:DXY"
                        containerRef={DXYReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56  h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/volatility"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="CAPITALCOM:VIX"
                        containerRef={VIXReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/dax"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="XETR:DAX"
                        containerRef={DAXReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/hsi"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="HSI:HSI"
                        containerRef={HSIReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/cac40"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="INDEX:CAC40"
                        containerRef={CAC40Reff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/nikkei"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="FRED:NIKKEI225"
                        containerRef={NIKKEIReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/nasdaq"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="NASDAQ:NDX"
                        containerRef={NASDAQReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
             {/* {tabSelected.currentTab === 2 && (
              <div ref={emblaRef} className="overflow-hidden">
                <div className="flex py-10 gap-4 ">
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/oil"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="NYMEX:CL1!"
                        containerRef={CLReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/gas"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="MOEX:NG1!"
                        containerRef={NGReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/gold"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="COMEX:GC1!"
                        containerRef={GCReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/platinum"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="NYMEX:PL1!"
                        containerRef={PLReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56  h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/lithium"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="COMEX:LTH1!"
                        containerRef={LTHReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/nickel"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="MOEX:NL1!"
                        containerRef={NLReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/cobalt"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="SGX:COH1!"
                        containerRef={COHReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                      <Link
                        to={
                          isLogin.auth?.accessToken &&
                          isLogin.auth?.role?.includes("user")
                            ? "/dashboard/copper"
                            : isLogin.auth?.accessToken &&
                              !isLogin.auth?.role?.includes("user")
                            ? "#"
                            : `${i18next.language}/sign-in`
                        }
                        className="absolute w-full h-full z-infinity"
                      ></Link>
                      <TradingViewWidget
                        symbol="MCX:COPPER1!"
                        containerRef={COPPERReff}
                        type="mini-chart"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </section>
      </div>
      {/* <div ref={emblaRef} className='overflow-hidden'>
        <div className='flex my-10 gap-4'>
          {mockInvest.map((item, i) => (
            <div className='flex flex-col' key={i}>
              <div className='w-56 lg:w-full h-48 lg:h-56 flex flex-col justify-between rounded-lg overflow-hidden shadow-lg p-2'>
                <div className='flex items-start gap-4'>
                  <div className='w-14 h-14'>
                    <img
                      src={item.icon}
                      alt={item.icon}
                      className='w-full h-full'
                    />
                  </div>
                  <div>
                    <div className='font-semibold text-md lg:text-xl'>
                      {item.crypto}
                    </div>
                    <div className='font-semibold text-md lg:text-xl'>
                      {item.currency}
                    </div>
                  </div>
                </div>
                <div className='flex items-start gap-4'>
                  <div className='w-1/2'>
                    <img
                      src={item.chart}
                      alt={item.chart}
                      className='w-full h-full'
                    />
                  </div>
                  <div className='w-1/2'>
                    <div className='font-semibold text-md lg:text-lg text-red-500'>
                      {item.percent}
                    </div>
                    <div className='text-xs lg:text-sm text-gray-500 opacity-75'>
                      1D change
                    </div>
                  </div>
                </div>
                <Link to='/sign-up'>
                  <ButtonLp className='capitalize w-full overflow-hidden'>
                    Sign Up
                  </ButtonLp>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div> */}
     
    </div>
  );
};

export default HomeInvest;
