import { create } from 'zustand';
import { Api } from '../../lib/api/axios';
import {
  riskDisclosure,
  returnPolicy,
  amlPolicy,
  privacyPolicy,
  aboutUs,
} from '../../lib/api/cms-route.constant';

function pageContentInitialState() {
  const initialState = {
    [riskDisclosure]: {},
    [returnPolicy]: {},
    [amlPolicy]: {},
    [privacyPolicy]: {},
    [aboutUs]: {},
  };
  return initialState;
}

const usePageContent = create((set, get) => ({
  content: pageContentInitialState(),
  getData: async (page, language) => {
    try {
      const res = await Api.get(`/cms/${page}/${language??'en'}`);
      set((state) => ({
        content: { ...state.content, [page]: res.data?.content },
      }));
      return res.data?.content?.content;
    } catch (error) {
      return error.response?.data;
    }
  },
  updateData: async (payload, page) => {
    try {
      let data = {};
      if (page === 'about-us' || page === 'risk-disclosure' || page === 'return-policy' ||
          page === 'aml-policy' || page === 'privacy-policy') {
        data = payload;
        data._id = get().content[page]?._id;
      } else {
        data.content = payload;
        data._id = get().content[page]?._id;
      }
      if (page === 'about-us' || page === 'risk-disclosure' || page === 'return-policy' || 
          page === 'aml-policy' || page === 'privacy-policy') {
        set((state) => ({
          content: {
            ...state.content,
            [page]: payload,
          },
        }));
      } else {
        set((state) => ({
          content: {
            ...state.content,
            [page]: { ...state.content[page], content: payload },
          },
        }));
      }
      console.log("data Page Content: " + data);
      const res = await Api.patch(`/cms/${page}`, data);
      return res.status;
    } catch (error) {
      return error.response?.data;
    }
  },
}));

export default usePageContent;
